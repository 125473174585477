import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useEffect, useState } from "react";
import { StatusRecordService } from "../../service/StatusRecordService";
import { CommonMethods, RouteComponentProps } from "../resources/CommonMethods";
import CustomError from "../resources/Error";
import ClaimsWidget from "./ClaimsWidget";
import { DashboardAttendanceModel } from "./Dashboard";
import ExternalCostWidget from "./ExternalCostWidget";
import HeaderCards from "./HeaderCards";
import MonthlyCostsWidget from "./MonthlyCostsWidget";
import CampaignService from "../../service/CampaignService";
import { Link } from "react-router-dom";

const srs = new StatusRecordService();
let commonMethods = new CommonMethods();
let campaignService = new CampaignService();

function BossDashboard(props: RouteComponentProps<any>) {
  // Loading, Error, Redirect
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMessage] = useState<string>("");

  const [outStandingApproval, setOutStandingApproval] = useState(0);
  const [campaignCount, setCampaignCount] = useState(0);
  const [dailyGCAttendance, setDailyGCAttendance] = useState(<></>);
  const [groupCompanyAttendance, setGroupCompanyAttendance] = useState("");
  const [dashboardModel, setDashboardModel] = useState(
    new DashboardAttendanceModel()
  );
  const [thisMonthNum] = useState(moment().format("M").toString());
  const [previousMonth] = useState(commonMethods.displayMonth_Full(new Date()));
  // currency
  const [selectedCurrency, setSelectedCurrency] = useState("SGD");
  const [currencies, setCurrencies] = useState([]);

  const [isBoss, setIsBoss] = useState<boolean>(false);
  const [isPremiumPlan, setIsPremiumPlan] = useState<boolean>(false);

  // Boss View
  useEffect(() => {
    const myAbortController: AbortController = new AbortController();
    let abortSignal = myAbortController.signal.aborted;
    if (props.userProfile.taskList.includes("bossaccess")) {
      if (abortSignal) {
        return;
      }
      setIsBoss(true);
    } else {
      if (abortSignal) {
        return;
      }
      setIsError(true);
      setIsLoading(false);
      setErrorMessage("Unauthorized to view this page");
    }

    commonMethods.hasPremiumPlanAccess().then((res) => {
      if (abortSignal) {
        return;
      }
      setIsPremiumPlan(res);
    });

    return () => {
      myAbortController.abort();
    };
  }, []);

  useEffect(() => {
    const myAbortController: AbortController = new AbortController();
    let abortSignal = myAbortController.signal.aborted;

    // Attendance parts
    getCompanyWideAttendance(abortSignal);
    // Header cards
    getOpenedCampaigns(abortSignal);
    getOutstanding(abortSignal);

    // Cost Overview part
    getCurrencies(abortSignal);

    return () => {
      myAbortController.abort();
    };
  }, [isBoss]);

  const getCurrencies = (abortSignal: boolean) => {
    commonMethods
      .getCurrencies(thisMonthNum)
      .then((res) => {
        if (abortSignal) {
          return;
        }
        setCurrencies(res);
      })
      .catch((err) => {
        if (abortSignal) {
          return;
        }
        let errorMsg = commonMethods.getErrorMessage(err);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(errorMsg);
      });
  };
  const getCompanyWideAttendance = (abortSignal: boolean) => {
    srs
      .getCompanyWideAttendance(new Date())
      .then((res) => {
        if (abortSignal) {
          return;
        }
        setDashboardModel(res);
        getPercentageGroupCompany(res);
        getPercentageCompany(res);
        // getCompanyOverviewDetails(res);
        // getPayrollDetails();
        setIsLoading(false);
      })
      .catch((err) => {
        if (abortSignal) {
          return;
        }
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(commonMethods.getErrorMessage(err));
      });
  };
  const getOutstanding = (abortSignal: boolean) => {
    srs
      .getStatusRecordByApprover()
      .then((res) => {
        if (abortSignal) {
          return;
        }
        setOutStandingApproval(res.length);
      })
      .catch((err) => {
        if (abortSignal) {
          return;
        }
        let errorMsg = commonMethods.getErrorMessage(err);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(errorMsg);
      });
  };

  const getOpenedCampaigns = (abortSignal: boolean) => {
    campaignService
      .getCampaignCountForTheMonth()
      .then((res) => {
        if (abortSignal) {
          return;
        }
        setCampaignCount(res);
      })
      .catch((err) => {
        if (abortSignal) {
          return;
        }
        let errorMsg = commonMethods.getErrorMessage(err);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(errorMsg);
      });
  };

  const getPercentageGroupCompany = (
    dashboardModel: DashboardAttendanceModel
  ) => {
    let totalUnavailInGroup = 0;
    let totalInGroup = 0;
    dashboardModel.totalUnavailInEachCompany.forEach((item) => {
      totalUnavailInGroup += item.noOfUsers;
    });
    dashboardModel.totalNoInEachCompany.forEach((item) => {
      totalInGroup += item.noOfUsers;
    });
    setGroupCompanyAttendance(
      totalInGroup - totalUnavailInGroup + "/" + totalInGroup
    );
  };

  const getPercentageCompany = (dashboardModel: DashboardAttendanceModel) => {
    // here is an array arrToDisplay
    let arrToDisplay: any[] = [];
    arrToDisplay.push(
      <tr key="headerkey">
        <td className="font-weight-bold">Company</td>
        <td className="font-weight-bold">Total Headcount</td>
        <td className="font-weight-bold">Unavailable</td>
      </tr>
    );

    dashboardModel.totalUnavailInEachCompany.forEach((item, index) => {
      // here is each item in the array. without this you have to manually type out each item?? like [0], [1]
      let row = (
        <tr key={index}>
          <td>{item.companyName}</td>
          <td>{dashboardModel.totalNoInEachCompany[index].noOfUsers}</td>
          <td>{item.noOfUsers}</td>
        </tr>
      );
      arrToDisplay.push(row);
    });

    let outerTable = (
      <div className="col-12 col-lg-12">
        <div
          className="card"
          data-tour-id="today-outlook"
          data-tour="This is a daily overview of the number of employees not in office."
        >
          <h1>Today's Outlook</h1>
          <table className="leave-info-dialog table text-center">
            {/* // here is where the array will be displayed.  */}
            <tbody>{arrToDisplay}</tbody>
          </table>
        </div>
      </div>
    );
    if (arrToDisplay.length === 1) {
      outerTable = <></>;
    }
    setDailyGCAttendance(outerTable);
  };

  const financesCharts = () => {
    let premiumwords;
    let cardName = "card";

    if (!isPremiumPlan) {
      premiumwords = (
        <div className="pb-2 text-danger">
          This page requires Premium access. To unlock, click{" "}
          <Link to="/priceplans" target="_blank">
            here
          </Link>
          .
        </div>
      );
      cardName = "card premium";
    }
    return (
      <div className="col-12 col-lg-8">
        <div
          className="card"
          data-tour-id="previous-month-overview"
          data-tour="Get a bird's eye view of all expense of all companies for the current month according to currency."
        >
          <h1>Overview - {previousMonth} </h1>
          {premiumwords}
          <div className="row pb-2">
            <div className="col">
              <div className="boldLabel">
                Currency {selectedCurrency !== "" ? selectedCurrency : ""}
              </div>
              <Dropdown
                options={currencies}
                onChange={(e) => setSelectedCurrency(e.value)}
                value={selectedCurrency}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6">
              <div
                className={cardName}
                data-tour-id="monthly-costs-chart"
                data-tour="You can click a category to exclude it from the chart."
                style={{ height: "90%" }}
              >
                <h1>Total Monthly Costs</h1>

                <MonthlyCostsWidget {...props} others={selectedCurrency} />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className={cardName}
                // data-tour-id="claims-chart"
                // data-tour="Here is an overview of the claims made this month."
                style={{ height: "90%" }}
              >
                <h1>Claims </h1>
                <ClaimsWidget {...props} others={selectedCurrency} />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className={cardName}
                // data-tour-id="op-costs-chart"
                // data-tour="Here are the fixed operational expenses for this month."
                style={{ height: "90%" }}
              >
                <h1>Operational Costs </h1>
                <ExternalCostWidget {...props} others={selectedCurrency} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const leaveMonthOverview = () => {
    return (
      <div className="datatable-centerHeader datatable-centerContent">
        <DataTable
          rowGroupMode="rowspan"
          groupField="companyName"
          sortField="companyName"
          sortOrder={1}
          value={dashboardModel.totalStatusTypesPerCompany}
          autoLayout={true}
          paginator={true}
          alwaysShowPaginator={false}
          rows={20}
          emptyMessage="No results found."
        >
          <Column className="p-col" field="companyName" header="Company" />
          <Column className="p-col" field="statusTypeName" header="Leave Type" />
          <Column className="p-col" field="noOfDays" header="No. of Employees" />
        </DataTable>
      </div>
    );
  };

  const groupKPIdisplay = () => {
    if (isPremiumPlan) {
      return (
        <div className="col-12 col-lg-12">
          <div className="card">
            <h1>KPI Overview</h1>
            <div>
              <span>
                Click{" "}
                <Link to="/indicators/groupcompany" target="_blank">
                  here
                </Link>{" "}
                for a general overview.
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      // not premium, need blur
      return (
        <div className="col-12 col-lg-12">
          <div className="card">
            <h1>KPI Overview</h1>
            <div className="pb-2 text-danger">
              This part requires Premium access. To unlock, click{" "}
              <Link to="/priceplans" target="_blank">
                here
              </Link>
              .
            </div>
            <div className="premium">
              <span>Click here for a general overview.</span>
            </div>
          </div>
        </div>
      );
    }
  };

  let currentMonthLeaveBreakdown = (
    <div className="col-12 col-lg-12">
      <div
        className="card"
        data-tour-id="leave-month-overview"
        data-tour="This is the number of employees who has applied for each leave type this month."
      >
        <h1>Current Month's Leave Breakdown</h1>
        {leaveMonthOverview()}
      </div>{" "}
    </div>
  );

  let page = (
    <>
      {financesCharts()}
      <div className="col-12 col-lg-4">
        <div className="row ">
          {groupKPIdisplay()}
          {dailyGCAttendance}
          {currentMonthLeaveBreakdown}
        </div>
      </div>
    </>
  );

  let display;
  if (isError) {
    display = <CustomError message={errorMsg} />;
  } else if (isLoading) {
    display = <ProgressSpinner />;
  } else {
    display = page;
  }

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="card-group pb-4">
            <HeaderCards
              title="Outstanding Approvals"
              content=""
              redirect={"/status/approve"}
              number={outStandingApproval}
              imageUrl="https://img.icons8.com/ultraviolet/40/000000/expired.png"
              dataTourId="outstanding-approvals"
              dataTour="These are the leave submissions you've yet to approve. If there are any, you can click this banner to head to the approval page."
            />
            <HeaderCards
              title="Group Company Attendance"
              content=""
              number={groupCompanyAttendance}
              imageUrl="https://img.icons8.com/ultraviolet/40/000000/todo-list.png"
              dataTourId="group-company-attendance"
              dataTour="This is the attendance of all companies for the day."
            />
            {isPremiumPlan && (
              <HeaderCards
                title={`Campaign Count (${commonMethods.displayMonth_Full(
                  new Date()
                )})`}
                content=""
                number={campaignCount}
                imageUrl="https://img.icons8.com/ultraviolet/40/000000/todo-list.png"
                dataTourId="boss-campaign-count"
                dataTour="This the number of campaigns opened this month."
              />
            )}
          </div>
        </div>
      </div>
      <div className="row">{display}</div>
    </div>
  );
}

export default BossDashboard;
