import * as H from "history";
import Cookies from "js-cookie";
import _ from "lodash";
import moment from "moment";
import { match, StaticContext } from "react-router";
import { HttpClient } from "../../service/HttpClient";
// import { UserProfileModel } from "./MyContext";
import { UserService } from "../../service/UserService";
import { UserAccStatus } from "../user/UserModels";
import { UserProfileModel } from "./ExportClass";

export interface RouteComponentProps<
  Params extends { [K in keyof Params]?: string } = {},
  C extends StaticContext = StaticContext,
  S = H.LocationState
> {
  history: H.History;
  location: H.Location<S>;
  match: match<Params>;
  staticContext?: C;
  userProfile: UserProfileModel;
  permission?: Array<string>;
  others: any;
}

export class AccessModel {
  planExpired: boolean = false;
  accActive: boolean = false;
  onboardingComplete: boolean = false;
}

export class DropdownModel {
  label: string = "";
  value: string = "";
}

export class StrictCheckModel {
  userID: string = "";
  companyID: string = "";
  groupCompanyID: string = "";
  permittedTask: Array<string> = [];
}

export enum ActionTypes {
  create = "Create",
  update = "Update",
}

export enum StatusTypes {
  active = "Active",
  deactivated = "Deactivated",
}

export interface ParamIDTypes {
  id: string;
}

export class CommonMethods {
  httpClient: HttpClient;
  userService: UserService;
  static EmptyGuid = "00000000-0000-0000-0000-000000000000";

  static DateForBackendStringFormat = "YYYY-MM-DDTHH:mm:ss";

  constructor() {
    this.httpClient = new HttpClient();
    this.userService = new UserService();
  }

  async strictCheck(
    userProfile: UserProfileModel,
    companyID: string,
    task: Array<string> | string
  ) {
    // first check if companyID is in listOfCompanyIDS
    if (!userProfile.listOfCompanyIds.includes(companyID)) {
      return false;
    }

    if (!Array.isArray(task)) {
      var temp = [];
      temp.push(task);
      task = temp;
    }

    var toCheck: StrictCheckModel = {
      userID: userProfile.userID,
      companyID: companyID,
      groupCompanyID: userProfile.groupCompanyID,
      permittedTask: task,
    };

    const res = await this.httpClient.post("/api/task/strictcheck", toCheck);
    return res;
  }

  //    ___         _
  //   |   \  __ _ | |_  ___  ___
  //   | |) |/ _` ||  _|/ -_)(_-<
  //   |___/ \__,_| \__|\___|/__/
  //

  displayDate(date: Date) {
    if (date === null) {
      return "";
    }
    return moment(date).format("DD/MM/YYYY");
  }

  displayDate_Short(date: Date) {
    if (date === null) {
      return "";
    }
    return moment(date).format("DD/MM/YY");
  }

  displayDate_Words(date: Date) {
    if (date === null) {
      return "";
    }
    return moment(date).format("DD MMM YYYY");
  }

  displayDate_Words_NoYear(date: Date) {
    if (date === null) {
      return "";
    }
    return moment(date).format("DD MMM");
  }

  displayMonth_Short(date: Date) {
    if (date === null) {
      return "";
    }
    return moment(date).format("MMM");
  }

  displayMonth_Full(date: Date) {
    if (date === null) {
      return "";
    }
    return moment(date).format("MMMM");
  }

  displayDateTime(date: Date) {
    if (date === null) {
      return "";
    }
    return moment(date).format("DD/MM/YYYY hh:mm A");
  }

  displayDateTime_Words(date: Date) {
    if (date === null) {
      return "";
    }
    return moment(date).format("DD MMM YYYY hh:mm A");
  }

  displayDate_FileName(date: Date) {
    if (date === null) {
      return "";
    }
    return moment(date).format("DD-MM-YYYY");
  }

  displayMonthYear(date: Date) {
    if (date === null) {
      return "";
    }
    return moment(date).format("MMM YYYY");
  }

  displayMonthYear_Full(date: Date) {
    if (date === null) {
      return "";
    }
    return moment(date).format("MMMM YYYY");
  }

  displayDay(date: Date) {
    if (date === null) {
      return "";
    }
    return moment(date).format("dddd");
  }

  formatDateDisplayForPayrollPeriod(payrolldate: Date) {
    return (
      moment(payrolldate).startOf("month").format("DD MMMM") +
      " - " +
      moment(payrolldate).endOf("month").format("DD MMMM YYYY")
    );
  }

  formatDateDisplayPayPeriod(start: Date, end: Date) {
    return (
      moment(start).format("DD MMM") + " - " + moment(end).format("DD MMM")
    );
  }

  formatDatePayDay(paydate: Date) {
    return this.displayDate_Words_NoYear(paydate);
  }

  calculateAge(dateOfBirth: Date) {
    return moment().diff(dateOfBirth, "years");
  }

  timingBasedLeaveTypes(leaveTypeName: string) {
    return (
      leaveTypeName === "Out Of Office" ||
      leaveTypeName === "Overtime" ||
      leaveTypeName === "Time-off" ||
      leaveTypeName === "Medical Appointment"
    );
  }

  convertDatesForDisplayNoticeBoard(
    leaveTypeName: string,
    startDate: Date,
    endDate: Date,
    duration: string
  ) {
    if (this.timingBasedLeaveTypes(leaveTypeName)) {
      return (
        moment(startDate).format("h:mmA") +
        " " +
        moment(endDate).format(" - h:mmA")
      );
    }
    let startdatepart = moment(startDate).format("D MMM");
    let enddatepart = moment(endDate).format("D MMM");
    let durationpart = duration.split(" - ");
    let startDuration = durationpart[0];
    let endDuration = durationpart[1];

    if (this.isSameDate(startDate, endDate)) {
      return startdatepart + " (" + startDuration + ")";
    }

    if (startDuration !== "FULL DAY"){
      startdatepart += " (" + startDuration + ")";
    }

    if (endDuration !== "FULL DAY"){
      enddatepart += " (" + endDuration + ")";
    }

    return `${startdatepart} - ${enddatepart}`;
  }

  convertDatesForLeaveFormDisplay(
    leaveTypeName: string,
    startDate: Date,
    endDate: Date,
    duration: string
  ) {
    if (this.timingBasedLeaveTypes(leaveTypeName)) {
      return (
        "on " +
        moment(startDate).format("ddd, DD/MM/YY") +
        " from " +
        moment(startDate).format("hh:mmA") +
        moment(endDate).format(" to hh:mmA")
      );
    }
    let startdatepart = moment(startDate).format("DD/MM/YYYY");
    let startdaypart = moment(startDate).format("ddd");
    let durationpart = duration.split(" - ");
    let startDuration = durationpart[0];

    if (this.isSameDate(startDate, endDate)) {
      return (
        "on " + startdaypart + ", " + startdatepart + " (" + startDuration + ")"
      );
    }

    let enddatepart = moment(endDate).format("DD/MM/YYYY");
    let enddaypart = moment(endDate).format("ddd");

    let endDuration = "";
    if (duration[1] !== undefined) {
      endDuration = durationpart[1];
    }

    return (
      "from " +
      startdaypart +
      ", " +
      startdatepart +
      " (" +
      startDuration +
      ")" +
      " to " +
      enddaypart +
      ", " +
      enddatepart +
      " (" +
      endDuration +
      ")"
    );
  }

  convertStartEndDateForStartEndDayDateDurationDisplay(
    leaveTypeName: string,
    startDate: Date,
    endDate: Date,
    duration: string
  ) {
    if (this.timingBasedLeaveTypes(leaveTypeName)) {
      return (
        moment(startDate).format("ddd, DD/MM/YYYY hh:mmA") +
        " " +
        moment(endDate).format(" - hh:mmA")
      );
    }
    let startdatepart = moment(startDate).format("DD/MM/YYYY");
    let startdaypart = moment(startDate).format("ddd");
    let durationpart = duration.split(" - ");
    let startDuration = durationpart[0];

    if (this.isSameDate(startDate, endDate)) {
      let addStartDuration = "";
      if (startDuration !== "") {
        addStartDuration = " (" + startDuration + ")";
      }
      return startdaypart + ", " + startdatepart + addStartDuration;
    }

    let enddatepart = moment(endDate).format("DD/MM/YYYY");
    let enddaypart = moment(endDate).format("ddd");

    let endDuration = "";
    if (duration[1] !== undefined) {
      endDuration = durationpart[1];
    }

    return (
      startdaypart +
      ", " +
      startdatepart +
      " (" +
      startDuration +
      ")" +
      " - " +
      enddaypart +
      ", " +
      enddatepart +
      " (" +
      endDuration +
      ")"
    );
  }

  convertStartEndDateForDayDateDurationDisplay(
    leaveTypeName: string,
    startDate: Date,
    endDate: Date,
    duration: string,
    startOrEnd: string
  ) {
    if (this.timingBasedLeaveTypes(leaveTypeName)) {
      if (startOrEnd === "start") {
        return moment(startDate).format("ddd, DD/MM/YYYY hh:mmA");
      } else {
        return moment(endDate).format("ddd, DD/MM/YYYY hh:mmA");
      }
    }

    let startdatepart = moment(startDate).format("DD/MM/YYYY");
    let startdaypart = moment(startDate).format("ddd");
    let durationpart = duration.split(" - ");
    let startDuration = durationpart[0];

    if (this.isSameDate(startDate, endDate)) {
      return startdaypart + ", " + startdatepart + " (" + startDuration + ")";
    }

    if (startOrEnd === "start") {
      return startdaypart + ", " + startdatepart + " (" + startDuration + ")";
    }
    let enddatepart = moment(endDate).format("DD/MM/YYYY");
    let enddaypart = moment(endDate).format("ddd");

    let endDuration = "";
    if (duration[1] !== undefined) {
      endDuration = durationpart[1];
    }

    if (startOrEnd === "end") {
      return enddaypart + ", " + enddatepart + " (" + endDuration + ")";
    }

    return "";
  }

  displayTime12Hour(date: Date) {
    return moment(date).format("h:mm A");
  }

  isSameDate(dateOne: Date | string, dateTwo: Date | string) {
    return (
      moment(dateOne).isSame(dateTwo, "year") &&
      moment(dateOne).isSame(dateTwo, "month") &&
      moment(dateOne).isSame(dateTwo, "day")
    );
  }

  createMaxDate(): Date {
    let today = new Date();
    let date = today.getDate();
    let month = today.getMonth();
    let year = today.getFullYear();
    let maxDate = new Date();
    maxDate.setDate(date);
    maxDate.setMonth(month);
    maxDate.setFullYear(year);
    return maxDate;
  }

  getTodayAsDate(): Date {
    let today = new Date();

    today.setMinutes(0);
    today.setSeconds(0);
    return today;
  }

  getTodayNowAsDate(): Date {
    let today = new Date();
    today.setMinutes(0);
    today.setSeconds(0);
    return today;
  }

  getYesterdayAsDate(): Date {
    let today = new Date();

    return moment(today)
      .subtract(1, "days")
      .hours(0)
      .minutes(0)
      .seconds(0)
      .toDate();
  }

  setStartOfDayOfDate(date: Date) {
    let newDate = new Date(date);
    newDate.setMinutes(0);
    newDate.setHours(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
  }

  setDateAtNoon(date: Date) {
    let newDate = new Date(date);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setHours(12);
    return newDate;
  }

  setDateAtTwoPM(date: Date) {
    let newDate = new Date(date);
    newDate.setMinutes(0);
    newDate.setHours(14);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
  }

  setEndOfDayOfDate(date: Date) {
    let newDate = new Date(date);
    newDate.setMinutes(59);
    newDate.setHours(23);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
  }

  getStartOfTodayAsDate(): Date {
    let today = new Date();
    today.setMinutes(0);
    today.setHours(0);
    today.setMilliseconds(0);
    today.setSeconds(0);

    return today;
  }

  getEndOfTodayAsDate(): Date {
    let today = new Date();
    today.setSeconds(59);
    today.setMinutes(59);
    today.setHours(23);
    return today;
  }

  isDateNull(dateToCheck: Date): boolean {
    if (
      dateToCheck === undefined ||
      dateToCheck === null ||
      Date.parse(dateToCheck.toString()) === 0 ||
      dateToCheck.toString() === ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  isDateStringNull(dateToCheck: string | Date): boolean {
    if (dateToCheck === "0001-01-01T00:00:00") {
      return true;
    }
    return false;
  }

  // used in claims and leave service
  convertDateToLocalTime(dateToCheck: Date) {
    return moment(dateToCheck)
      .add("minutes", moment().utcOffset())
      .format("YYYY-MM-DDTHH:mm:ss")
      .toString();
  }

  updateProperty(property: string, value: any, object: any) {
    let tempUserObj = object;
    (tempUserObj as any)[property] = value;
    return tempUserObj;
  }

  //     ___                                    _                     _                                  _    _             _
  //    / __| ___  _ __   _ __   ___  _ _    __| | _ _  ___  _ __  __| | ___ __ __ __ _ _    _ __   ___ | |_ | |_   ___  __| | ___
  //   | (__ / _ \| '  \ | '  \ / _ \| ' \  / _` || '_|/ _ \| '_ \/ _` |/ _ \\ V  V /| ' \  | '  \ / -_)|  _|| ' \ / _ \/ _` |(_-<
  //    \___|\___/|_|_|_||_|_|_|\___/|_||_| \__,_||_|  \___/| .__/\__,_|\___/ \_/\_/ |_||_| |_|_|_|\___| \__||_||_|\___/\__,_|/__/
  //                                                        |_|

  getContractFilterOptions() {
    return [
      { label: "All Contracts", value: "all" },
      { label: "Incomplete Contracts", value: "incomplete" },
      { label: "Expired Contracts", value: "expired" },
    ];
  }

  getDurationOptions() {
    return [
      { label: "FULL", value: "FULL DAY" },
      { label: "AM", value: "AM" },
      { label: "PM", value: "PM" },
    ];
  }

  getViewStatusDropdownList() {
    let statusDropdownList = [
      { label: UserAccStatus.Active, value: UserAccStatus.Active },
      { label: UserAccStatus.Deactivated, value: UserAccStatus.Deactivated },
      { label: "All", value: "All" },
    ];
    return statusDropdownList;
  }

  getWorkPassTypeDropdown() {
    let workPassDropdownList = [
      { label: "S Pass", value: "S Pass" },
      { label: "Employment Pass", value: "Employment Pass" }
    ];
    return workPassDropdownList;
  }

  getWorkLocationDropdown() {
    let workLocationDropdownList = [
      { label: "Remote Work", value: "Remote" },
      { label: "Working in Singapore", value: "SG" }
    ];
    return workLocationDropdownList;
  }

  getStatusSelectionDropdownList() {
    let statusDropdownList = [
      { label: UserAccStatus.Active, value: UserAccStatus.Active },
      { label: UserAccStatus.Deactivated, value: UserAccStatus.Deactivated },
    ];
    return statusDropdownList;
  }

  getViewUserStatusDropdownList() {
    let statusDropdownList = [
      { label: UserAccStatus.Active, value: UserAccStatus.Active },
      { label: UserAccStatus.Onboarding, value: UserAccStatus.Onboarding },
      { label: UserAccStatus.Deactivated, value: UserAccStatus.Deactivated },
      { label: "All", value: "All" },
    ];
    return statusDropdownList;
  }

  getTimeZoneList() {
    let timezoneList = [
      { label: "UTC-12:00", value: "-12" },
      { label: "UTC-11:00", value: "-11" },
      { label: "UTC-10:00", value: "-10" },
      { label: "UTC-9:30", value: "-9.5" },
      { label: "UTC-9:00", value: "-9" },
      { label: "UTC-8:00", value: "-8" },
      { label: "UTC-7:00", value: "-7" },
      { label: "UTC-6:00", value: "-6" },
      { label: "UTC-5:00", value: "-5" },
      { label: "UTC-4:00", value: "-4" },
      { label: "UTC-3:30", value: "-3.5" },
      { label: "UTC-3:00", value: "-3" },
      { label: "UTC-2:00", value: "-2" },
      { label: "UTC-1:00", value: "-1" },
      { label: "UTC", value: "0" },
      { label: "UTC+1:00", value: "1" },
      { label: "UTC+2:00", value: "2" },
      { label: "UTC+3:00", value: "3" },
      { label: "UTC+3:30", value: "3.5" },
      { label: "UTC+4:00", value: "4" },
      { label: "UTC+4:30", value: "4.5" },
      { label: "UTC+5:00", value: "5" },
      { label: "UTC+5:30", value: "5.5" },
      { label: "UTC+5:45", value: "5.75" },
      { label: "UTC+6:00", value: "6" },
      { label: "UTC+7:00", value: "7" },
      { label: "UTC+8:00", value: "8" },
      { label: "UTC+8:45", value: "8.75" },
      { label: "UTC+9:00", value: "9" },
      { label: "UTC+9:30", value: "9.5" },
      { label: "UTC+10:00", value: "10" },
      { label: "UTC+10:30", value: "10.5" },
      { label: "UTC+11:00", value: "11" },
      { label: "UTC+12:00", value: "12" },
      { label: "UTC+12:45", value: "12.75" },
      { label: "UTC+13:00", value: "13" },
      { label: "UTC+14:00", value: "14" },
    ];

    return timezoneList;
  }

  getGenderDropdownList() {
    let genderDropdownList = [
      { label: "Female", value: "F" },
      { label: "Male", value: "M" },
    ];
    return genderDropdownList;
  }

  getYesNoDropdownList() {
    let yesNoDropdownList = [
      { label: "Yes", value: "Y" },
      { label: "No", value: "N" },
    ];
    return yesNoDropdownList;
  }

  getSalutationDropdownList() {
    let salutationDropdownList = [
      { label: "Mr", value: "Mr" },
      { label: "Mrs", value: "Mrs" },
      { label: "Miss", value: "Miss" },
      { label: "Ms", value: "Ms" },
    ];
    return salutationDropdownList;
  }

  getBudgetingGroupDropdownList(){
	let budgetingGroupList = [
		{ label: "Social Media Team", value: "Social Media Team" },
		{ label: "Innov8", value: "Innov8" },
		{ label: "TSL Editorial (SG)", value: "TSL Editorial (SG)" },
		{ label: "TSL Editorial (Group)", value: "TSL Editorial (Group)" },
		{ label: "TSL Regional (SG)", value: "TSL Regional (SG)" },
		{ label: "TSL Regional (Vietnam)", value: "TSL Regional (Vietnam)" },
		{ label: "TSL Regional (Philippines)", value: "TSL Regional (Philippines)" },
		{ label: "TSL Malaysia", value: "TSL Malaysia" },
		{ label: "TSL Thailand", value: "TSL Thailand" },
		{ label: "TSL Japan", value: "TSL Japan" },
		{ label: "TSL South Korea", value: "TSL South Korea" },
		{ label: "Youtube", value: "Youtube" },
		{ label: "Creative", value: "Creative" },
		{ label: "TSL Pictures", value: "TSL Pictures" },
		{ label: "Think Tank", value: "Think Tank" },
		{ label: "PMO", value: "PMO" },
		{ label: "Sales", value: "Sales" },
		{ label: "HR", value: "HR" },
		{ label: "Tech", value: "Tech" },
		{ label: "Finance", value: "Finance" },
		{ label: "Admin", value: "Admin" },
		{ label: "Eatbook", value: "Eatbook" },
		{ label: "MustShareNews", value: "MustShareNews" },
		{ label: "Zula", value: "Zula" },
		{ label: "KOL Team", value: "KOL Team" },
		{ label: "CEO Office", value: "CEO Office" },
		{ label: "Special Projects", value: "Special Projects" }
	];
	return budgetingGroupList;
  }

  getLegalStatusDropdownList() {
    let legalStatusDropdownList = [
      { label: "Singapore Citizen", value: "SC" },
      { label: "Singapore Permanent Resident", value: "PR" },
      { label: "Foreigner", value: "FR" },
    ];
    return legalStatusDropdownList;
  }

  getMaritalStatusDropdownList() {
    let legalStatusDropdownList = [
      { label: "Single", value: "Single" },
      { label: "Married", value: "Married" },
      { label: "Widowed", value: "Widowed" },
      { label: "Separated", value: "Separated" },
      { label: "Divorced", value: "Divorced" },
    ];
    return legalStatusDropdownList;
  }

  getPrimaryFilterOptions() {
    let primaryFilterOptions = [
      { label: "All", value: "" },
      { label: "Department", value: "Department" },
      { label: "Role", value: "Role" },
      { label: "User Rank", value: "UserRank" },
      { label: "Job Title", value: "JobTitle" },
    ];
    return primaryFilterOptions;
  }

  getOrderByOptions() {
    let orderByOptions = [
      { label: "Alphabatical Name", value: "Alphabatical Name" },
      { label: "Department", value: "Department" },
      { label: "Date Joined", value: "Date Joined" },
      { label: "Contract Expiry", value: "Contract Expiry" },
    ];
    return orderByOptions;
  }

  getContractAllStatusOptions() {
    let contractStatusOptions = [
      { label: "Active", value: "Active" },
      { label: "Signed", value: "Signed" },
      { label: "Pending", value: "Pending" },
      { label: "Ended", value: "Ended" },
      { label: "Nullified", value: "Nullified" },
    ];
    return contractStatusOptions;
  }

  getContractEditStatusOptions() {
    let contractStatusOptions = [
      { label: "Signed", value: "Signed" },
      { label: "Pending", value: "Pending" },
      { label: "Nullified", value: "Nullified" },
    ];
    return contractStatusOptions;
  }

  getContractEditExistingUserStatusOptions() {
    let contractStatusOptions = [
      { label: "Active", value: "Active" },
      { label: "Signed", value: "Signed" },
      { label: "Pending", value: "Pending" },
      { label: "Nullified", value: "Nullified" },
    ];
    return contractStatusOptions;
  }

  getContractCreateStatusOptions() {
    let contractStatusOptions = [
      { label: "Active", value: "Active" },
      { label: "Signed", value: "Signed" },
      { label: "Pending", value: "Pending" },
    ];
    return contractStatusOptions;
  }

  getFreshCreateContractStatusOption() {
    let contractStatusOptions = [
      { label: "Signed", value: "Signed" },
      { label: "Pending", value: "Pending" },
    ];
    return contractStatusOptions;
  }

  getIndustryDropdownOptions() {
    let industryList = [
      {
        label: "Accomodation and Food Service Activities",
        value: "Accomodation and Food Service Activities",
      },
      {
        label: "Activities Not Adequately Defined",
        value: "Activities Not Adequately Defined",
      },
      {
        label: "Activities of Extra-Territorial Organisations and Bodies",
        value: "Activities of Extra-Territorial Organisations and Bodies",
      },
      {
        label: "Activities of Households as Employers of Domestic Personnel",
        value: "Activities of Households as Employers of Domestic Personnel",
      },
      {
        label: "Administrative and Support Service Activities",
        value: "Administrative and Support Service Activities",
      },

      {
        label: "Agriculture and Fishing",
        value: "Agriculture and Fishing",
      },
      { label: "Arts", value: "Arts" },

      { label: "Construction", value: "Construction" },
      { label: "Education", value: "Education" },
      { label: "Electricity", value: "Electricity" },
      {
        label: "Entertainment and Recreation",
        value: "Entertainment and Recreation",
      },
      {
        label: "Financial and Insurance Activities",
        value: "Financial and Insurance Activities",
      },
      { label: "Gas", value: "Gas" },
      {
        label: "Health and Social Services",
        value: "Health and Social Services",
      },
      {
        label: "Information and Communications",
        value: "Information and Communications",
      },
      { label: "Manufacturing", value: "Manufacturing" },
      { label: "Mining and Quarrying", value: "Mining and Quarrying" },
      {
        label: "Other Service Activities",
        value: "Other Service Activities",
      },
      { label: "Professional", value: "Professional" },
      {
        label: "Public Administration and Defence",
        value: "Public Administration and Defence",
      },
      {
        label: "Real Estate Activities",
        value: "Real Estate Activities",
      },
      {
        label: "Scientific and Technical Activities",
        value: "Scientific and Technical Activities",
      },
      { label: "Sewerage", value: "Sewerage" },
      {
        label: "Steam and Air-Conditioning Supply",
        value: "Steam and Air-Conditioning Supply",
      },
      {
        label: "Transportation and Storage",
        value: "Transportation and Storage",
      },
      {
        label: "Waste Management and Remediation Activities",
        value: "Waste Management and Remediation Activities",
      },
      { label: "Water Supply", value: "Water Supply" },
      {
        label: "Wholesale and Retail Trade",
        value: "Wholesale and Retail Trade",
      },
    ];
    return industryList;
  }

  getSalaryPeriodOptions() {
    let salaryPeriodOptions = [
      { label: "Monthly", value: "Monthly" },
      // TODO: ADD BACK IN FUTURE WHEN WE SUPPORT HOURLY LOL
      // { label: "Hourly", value: "Hourly" },
    ];
    return salaryPeriodOptions;
  }

  getContractTypeOptions() {
    let contractStatusOptions = [
      { label: "New", value: "New" },
      { label: "Amended", value: "Amended" },
    ];
    return contractStatusOptions;
  }

  getSHGDropdownOptions() {
    let contractStatusOptions = [
      { label: "Chinese Development Assistance Council", value: "CDAC" },
      { label: "Eurasian Community Fund", value: "ECF" },
      { label: "Mosque Building and Mendaki Fund", value: "MBMF" },
      {
        label: "Singapore Indian Development Association",
        value: "SINDA",
      },
    ];
    return contractStatusOptions;
  }

  getCampaignStatusDropdown() {
    let campaignStatusOptions = [
      { label: "In Progress", value: "In Progress" },
      { label: "Half Published", value: "Half Published" },
      { label: "Published", value: "Published" },
      { label: "Cancelled", value: "Cancelled" },
    ];
    return campaignStatusOptions;
  }

  getCampaignStatusViewDropdown() {
    let campaignStatusOptions = [
      { label: "All", value: "" },
      { label: "In Progress", value: "In Progress" },
      { label: "Half Published", value: "Half Published" },
      { label: "Published", value: "Published" },
      { label: "Cancelled", value: "Cancelled" },
    ];
    return campaignStatusOptions;
  }

  getDeliverableStatusDropdown() {
    let delstatusoptions = [
      { label: "In Progress", value: "In Progress" },
      { label: "Published", value: "Published" },
      { label: "Cancelled", value: "Cancelled" },
    ];
    return delstatusoptions;
  }

  getDeliverableStatusViewDropdown() {
    let delstatusoptions = [
      { label: "All", value: "" },
      { label: "In Progress", value: "In Progress" },
      { label: "Published", value: "Published" },
      { label: "Cancelled", value: "Cancelled" },
    ];
    return delstatusoptions;
  }

  getLeadStatusDropdown() {
    return [
      { label: "Cold Lead", value: "Cold Lead" },
      { label: "Warm Lead", value: "Warm Lead" },
      { label: "Ongoing", value: "Ongoing" },
      { label: "To Follow Up", value: "To Follow Up" },
    ];
  }

  getWorkingDaysOptions() {
    return [
      { label: "Su", value: "0" },
      { label: "Mo", value: "1" },
      { label: "Tu", value: "2" },
      { label: "We", value: "3" },
      { label: "Th", value: "4" },
      { label: "Fr", value: "5" },
      { label: "Sa", value: "6" },
    ];
  }

  //    ___                      _                                             _
  //   |   \  _ _  ___  _ __  __| | ___ __ __ __ _ _   ___  ___ ___  _ _ __ __(_) __  ___  ___
  //   | |) || '_|/ _ \| '_ \/ _` |/ _ \\ V  V /| ' \ (_-< (_-</ -_)| '_|\ V /| |/ _|/ -_)(_-<
  //   |___/ |_|  \___/| .__/\__,_|\___/ \_/\_/ |_||_|/__/ /__/\___||_|   \_/ |_|\__|\___|/__/
  //                   |_|

  async getCostTypeDropdown() {
    const res = await this.httpClient.get("/api/dropdown/costtype");
    return res;
  }
  async getCostTypeDropdownFilter() {
    const res = await this.httpClient.get("/api/dropdown/costtypefilter");
    return res;
  }
  async getCampaignTypeDropdown() {
    const res = await this.httpClient.get("/api/dropdown/campaigntype");
    return res;
  }
  async getPaymentTermsDropdown() {
    const res = await this.httpClient.get("/api/dropdown/paymentterms");
    return res;
  }

  async getCountryDropdown() {
    const res = await this.httpClient.get("/api/dropdown/countries");
    return res;
  }

  async getNationalityDropdown() {
    const res = await this.httpClient.get("/api/dropdown/nationality");
    return res;
  }

  async getCurrencyDropdown() {
    const res = await this.httpClient.get("/api/dropdown/currency");
    return res;
  }

  async getLeaveStatusDropdownForFiltering() {
    const res = await this.httpClient.get("/api/dropdown/leavestatus/filter");
    return res;
  }

  async getWeatherData(dateStr: Date) {
    const res = await this.httpClient.get(
      "https://api.data.gov.sg/v1/environment/2-hour-weather-forecast?date_time" +
        dateStr
    );
    return res;
  }
  async getTemperature(dateStr: Date) {
    const res = await this.httpClient.get(
      "https://api.data.gov.sg/v1/environment/air-temperature?date_time" +
        dateStr
    );
    return res;
  }

  async getCompanyDropdownListByGcid() {
    const res = await this.httpClient.get("/api/dropdown/company");
    return res;
  }

  async getCompanyDropdownAccordingToUserRole() {
    const res = await this.httpClient.get("/api/dropdown/company/userrole");
    return res;
  }

  async getDepartmentDropdownList(companyid: string) {
    return await this.httpClient.get("/api/dropdown/department/" + companyid);
  }

  async getDepartmentDropdownListByGcid(gcid: string) {
    return await this.httpClient.get("/api/dropdown/department/gcid/" + gcid);
  }

  async getDepartmentDropdownListForFiltering(companyid: string) {
    const res = await this.httpClient.get(
      "/api/dropdown/department/" + companyid + "/filter"
    );
    return res;
  }

  async getRoleDropdownListByGcid(id: string) {
    try {
      const res = await this.httpClient.get("/api/dropdown/role/gcid/" + id);
      return res;
    } catch (err) {
      return err;
    }
  }

  async getRoleDropdownListByCid(id: string) {
    try {
      const res = await this.httpClient.get("/api/dropdown/role/cid/" + id);
      return res;
    } catch (err) {
      return err;
    }
  }

  async getRolePickListDropdownList() {
    try {
      const res = await this.httpClient.get(
        "/api/dropdown/role/picklistDropdown"
      );
      return res;
    } catch (err) {
      return err;
    }
  }

  async getContractDropdown() {
    const res = await this.httpClient.get("/api/dropdown/contracts");
    return res;
  }

  async getContractSignedDropdown() {
    const res = await this.httpClient.get("/api/dropdown/contracts/signed");
    return res;
  }

  async getUserRankDropdown() {
    const res = await this.httpClient.get("/api/dropdown/userrank");
    return res;
  }

  async getUserDropdownForGroupCompany() {
    const res = await this.httpClient.get("/api/dropdown/user");
    return res;
  }

  async getInactiveUserDropdownByGroupCompany() {
    const res = await this.httpClient.get("/api/dropdown/inactive");
    return res;
  }

  async getUserDropdownByCompany(id: string) {
    const res = await this.httpClient.get("/api/dropdown/user/company/" + id);
    return res;
  }

  async getInactiveUsersDropdownByCompany(id: string) {
    const res = await this.httpClient.get("/api/dropdown/inactive/company/" + id);
    return res;
  }

  async getDeptJobTitleDropdown(departmentid: string) {
    const res = await this.httpClient.get(
      "/api/dropdown/jobtitle/dept/" + departmentid
    );
    return res;
  }

  async getCompanyJobTitleDropdown(companyid: string) {
    const res = await this.httpClient.get(
      "/api/dropdown/jobtitle/company/" + companyid
    );
    return res;
  }

  async getBankCodeDropdown() {
    const res = await this.httpClient.get("/api/dropdown/swift");
    return res;
  }

  async getBankNames(){
    const res = await this.httpClient.get("/api/bankcode/names");
    return res;
  }

  async getSwiftCode() {
    const res = await this.httpClient.get("/api/bankcode/swift");
    return res;
  }

  async getClientDropdown() {
    let gcid = await this.getGroupCompanyIDFromUserID();
    const res = await this.httpClient.get("/api/dropdown/client/" + gcid);
    return res;
  }

  async getClientContactDropdown(id: string) {
    const res = await this.httpClient.get("/api/dropdown/clientcontact/" + id);
    return res;
  }

  async getClientContactDropdownWithNone(id: string) {
    const res = await this.httpClient.get("/api/dropdown/clientcontact/" + id);
    let none = { label: "None", value: null };
    res.unshift(none);
    return res;
  }

  async getExternalCostClientDropdown() {
    const res = await this.httpClient.get("/api/dropdown/externalcostclient");
    return res;
  }

  async getDeliverableTypeDropdown() {
    const res = await this.httpClient.get("/api/dropdown/deliverabletype");
    return res;
  }

  async getDeliverableUserDropdown() {
    const res = await this.httpClient.get("/api/dropdown/deliverablebyuser");
    return res;
  }

  async getTasksUserDropdown() {
    const res = await this.httpClient.get("/api/dropdown/tasksbyuser");
    return res;
  }

  async getAllTasksUserDropdown() {
    const res = await this.httpClient.get("/api/dropdown/alltasksbyuser");
    return res;
  }

  async getCustomTasksUserDropdown() {
    const res = await this.httpClient.get("/api/dropdown/customtasksbyuser");
    return res;
  }

  async getCampaignsUserDropdown() {
    return await this.httpClient.get("/api/dropdown/campaignbyuser");
  }

  async getDocumentTypeDropdown() {
    const res = await this.httpClient.get("/api/dropdown/documenttype");
    return res;
  }

  async getCurrencies(month: string) {
    const res = await this.httpClient.get(`/api/dropdown/currencies/` + month);
    return res;
  }

  addCommonCurrencies(res: { label: string; value: string }[]) {
    res = _.reject(res, { label: "MYR RM", value: "MYR" });
    res = _.reject(res, { label: "THB ฿", value: "THB" });
    res = _.reject(res, { label: "SGD $", value: "SGD" });
    res.unshift({ label: "MYR RM", value: "MYR" });
    res.unshift({ label: "THB ฿", value: "THB" });
    res.unshift({ label: "SGD $", value: "SGD" });
    return res;
  }

  async getClientCompanyIndustryDropdown() {
    const res = await this.httpClient.get(`/api/dropdown/industries`);
    return res;
  }

  async getClientContactIndustryDropdown() {
    const res = await this.httpClient.get(`/api/dropdown/industries/myclients`);
    return res;
  }
  async getLogsStatusDropdown() {
    const res = await this.httpClient.get(`/api/dropdown/logstatus`);
    return res;
  }
  async getLogsTypeDropdown() {
    const res = await this.httpClient.get(`/api/dropdown/logstype`);
    return res;
  }

  async getPublisherDropdown() {
    const res = await this.httpClient.get(`/api/dropdown/publisher`);
    return res;
  }

  async getClaimStatusDropdown() {
    const res = await this.httpClient.get(`/api/dropdown/claimstatuses/filter`);
    return res;
  }
  async getAdminSettableClaimStatus() {
    const res = await this.httpClient.get(`/api/dropdown/claimstatuses/admin`);
    return res;
  }
  getIdentityTypeDropdown() {
    let primaryFilterOptions = [
      { label: "Residents (NRIC/FIN)", value: "R" },
      { label: "Non-Residents (Passport)", value: "NR" },
    ];
    return primaryFilterOptions;
  }

  async getAccountingCodeDropdown() {
    const res = await this.httpClient.get("/api/dropdown/accountingcode");
    return res;
  }

  //    ___  _           __   __    __                     ___            _    _
  //   / __|| |_  _  _  / _| / _|  / _| _ _  ___  _ __    / __| ___  ___ | |__(_) ___
  //   \__ \|  _|| || ||  _||  _| |  _|| '_|/ _ \| '  \  | (__ / _ \/ _ \| / /| |/ -_)
  //   |___/ \__| \_,_||_|  |_|   |_|  |_|  \___/|_|_|_|  \___|\___/\___/|_\_\|_|\___|
  //

  getUserIDFromToken() {
    const user = Cookies.get("user");
    if (user === undefined || null) {
      return "";
    }
    return user;
  }

  getToken() {
    // If token is undefined = HTTPOnly = cannot read by javascript.
    const token = Cookies.get("token");
    return token;
  }

  getGroupCompanyIDFromUserID() {
    return this.userService.getLookupIDFromUserIDService("gcid");
  }

  getCompanyIDFromUserID() {
    return this.getLookupIDFromUserIDService("companyid");
  }

  async getLookupIDFromUserIDService(lookupid: string) {
    const res = await this.httpClient.get("/api/user/" + lookupid);
    return res;
  }

  async getDepartmentIDFromUserID() {
    const res = await this.httpClient.get(`/api/user/departmentID`);
    return res;
  }

  async getFullDisplayNameFromProvidedUserID(id: string) {
    const res = await this.httpClient.get("/api/user/fullDisplayName/" + id);
    return res;
  }

  async checkIsUserRolePermitted(taskid: string) {
    const res = await this.httpClient.post("/api/common/permission", {
      TaskID: taskid,
    });
    return res;
  }

  // App.tsx -> Get Permitted Access to view Menu Modules
  async getPermittedMenuModules() {
    const res = await this.httpClient.get("/api/task/getPermittedModules");
    return res;
  }

  //    ___                       __  __                                  __                    ___                        ___   _      _
  //   | __| _ _  _ _  ___  _ _  |  \/  | ___  ___ ___ __ _  __ _  ___   / _| _ _  ___  _ __   | __| _ _  _ _  ___  _ _   / _ \ | |__  (_)
  //   | _| | '_|| '_|/ _ \| '_| | |\/| |/ -_)(_-<(_-</ _` |/ _` |/ -_) |  _|| '_|/ _ \| '  \  | _| | '_|| '_|/ _ \| '_| | (_) || '_ \ | |
  //   |___||_|  |_|  \___/|_|   |_|  |_|\___|/__//__/\__,_|\__, |\___| |_|  |_|  \___/|_|_|_| |___||_|  |_|  \___/|_|    \___/ |_.__/_/ |
  //                                                        |___/                                                                    |__/

  isWarning(error: any) {
    if (error.response.data.warning !== undefined) {
      return true;
    }
    return false;
  }

  getErrorMessage(error: any) {
    try {
      if (error.response.data.error !== undefined) {
        return error.response.data.error;
      } else if (error.response.data.warning !== undefined) {
        return error.response.data.warning;
      } else if (error.response.status === 500) {
        return "Unauthorized Access";
        // if (error.response.data.includes("Unauthorized Access")) {
        //   return "Unauthorized Access - Error: " + error.response.status;
        // }
      }
    } catch (e) {
      return "Error";
    }

    return "Error";
  }

  //    ___                   _          _                _                          ___       _    _    _
  //   | _ \ ___  _ _  _ __  (_) ___ ___(_) ___  _ _     /_\   __  __  ___  ___ ___ / __| ___ | |_ | |_ (_) _ _   __ _
  //   |  _// -_)| '_|| '  \ | |(_-<(_-<| |/ _ \| ' \   / _ \ / _|/ _|/ -_)(_-<(_-< \__ \/ -_)|  _||  _|| || ' \ / _` |
  //   |_|  \___||_|  |_|_|_||_|/__//__/|_|\___/|_||_| /_/ \_\\__|\__|\___|/__//__/ |___/\___| \__| \__||_||_||_|\__, |
  //                                                                                                             |___/

  // Can check if user has TaskAccess permitted
  isArrayPresentInArray(arr1: string[], arr2: string[]) {
    return arr2.some(function (v) {
      return arr1.indexOf(v) >= 0;
    });
  }

  isPropsLoaded(appProps: RouteComponentProps<any>) {
    if (
      appProps.userProfile !== undefined &&
      appProps.userProfile.companyID !== "" &&
      appProps.userProfile.groupCompanyID !== "" &&
      appProps.userProfile.listOfCompanyIds !== undefined &&
      appProps.userProfile.taskList !== []
    ) {
      return true;
    }
    return false;
  }

  async getContext() {
    const res = await this.httpClient.get("/api/common/ctxt");
    return res;
  }

  //               _  _     _        _    _
  //   __ __ __ _ | |(_) __| | __ _ | |_ (_) ___  _ _
  //   \ V // _` || || |/ _` |/ _` ||  _|| |/ _ \| ' \
  //    \_/ \__,_||_||_|\__,_|\__,_| \__||_|\___/|_||_|
  //

  validateEmail(email: string) {
    if (email === null || email.trim() === "") {
      return true;
    }
    //eslint-disable-next-line
    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  }

  validateDOB(dateofbirth: Date) {
    let today = new Date();
    if (dateofbirth > today) {
      return false;
    }
    return true;
  }

  generatePassword() {
    var uppercasechars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var numbers = "0123456789";
    var randomNum1 = Math.floor(Math.random() * 26);
    var randomNum2 = Math.floor(Math.random() * 10);
    var randomUpperAlpha = uppercasechars.charAt(randomNum1);
    var randomNum = numbers.charAt(randomNum2);
    var pw = Math.random().toString(36).substr(2, 7);
    pw += randomUpperAlpha + randomNum;

    return pw;
  }

  pwValidation = (pw: string) => {
    let regexpUpper = /[A-Z]/;
    let number = /[0-9]/;
    if (regexpUpper.test(pw) && number.test(pw) && pw.length >= 8) {
      return true;
    }
    return false;
  };

  //    ___             _     _                        _
  //   |   \  __ _  ___| |_  | |__  ___  __ _  _ _  __| |
  //   | |) |/ _` |(_-<| ' \ | '_ \/ _ \/ _` || '_|/ _` |
  //   |___/ \__,_|/__/|_||_||_.__/\___/\__,_||_|  \__,_|
  //

  async getTasksOfViewingUserOfCompany(companyID: string) {
    const res = await this.httpClient.get(
      "/api/dashboard/admin/" + companyID + "/task"
    );
    return res;
  }

  async getMenuOfViewingUserOfCompany(companyID: string) {
    const res = await this.httpClient.get(
      "/api/dashboard/admin/" + companyID + "/menu"
    );
    return res;
  }

  async getCompanyNameFromID(companyID: string) {
    const res = await this.httpClient.get("/api/company/name/" + companyID);
    return res;
  }

  async onboardingExpiredDeactivatedCheck() {
    const res = await this.httpClient.get("/api/authentication/checks");
    // let newObj: AccessModel = new AccessModel();
    // newObj.onboardingComplete = res.onboardingComplete;
    // newObj.planExpired = res.planExpired;
    // newObj.accActive = res.accActive;
    return res;
  }

  async hasPremiumPlanAccess() {
    const res = await this.httpClient.get("/api/authentication/premiumchecks");

    return res;
  }

  async isGroupCompanyTSL(gcid: string) {
    const res = await this.httpClient.get("/api/authentication/tsl/" + gcid);

    return res;
  }

  // async getTopPostByGCID(gcid: string) {
  //   const res = await this.httpClient.get("/api/dashboard/toppost/" + gcid);
  //   return res;
  // }

  async getTopPosts() {
    const res = await this.httpClient.get("/api/insightsrn/best-post");
    return res;
  }

  //     ___       _
  //    / __| ___ | | ___  _  _  _ _  ___
  //   | (__ / _ \| |/ _ \| || || '_|(_-<
  //    \___|\___/|_|\___/ \_,_||_|  /__/
  //

  getColourArr() {
    let colorArray: string[] = [
      // hm add
      "#90caf9",
      "#9fa8da",
      "#b39ddb",
      "#ce93d8",
      "#f48fb1",
      "#ef9a9a",

      "#ffe082",
      "#fff59d",
      "#c5e1a5",
      "#80cbc4",
      "#80deea",
      "#81d4fa",

      "#9575cd",
      "#ba68c8",
      "#f06292",
      "#e57373",
      "#b0bec5",
      "#ffab91",

      "#81c784",
      "#4db6ac",
      "#4dd0e1",
      "#4fc3f7",
      "#64b5f6",
      "#7986cb",

      "#ff8a65",
      "#ffb74d",
      "#ffd54f",
      "#fff176",
      "#dce775",
      "#aed581",

      "#42a5f5",
      "#5c6bc0",
      "#7e57c2",
      "#ab47bc",
      "#ec407a",
      "#ef5350",

      "#ffee58",
      "#9ccc65",
      "#66bb6a",
      "#26a69a",
      "#26c6da",
      "#29b6f6",

      "#9c27b0",
      "#e91e63",
      "#f44336",
      "#ff7043",
      "#ffa726",
      "#ffca28",

      "#1e88e5",
      "#3949ab",
      "#5e35b1",
      "#8e24aa",
      "#d81b60",
      "#e53935",

      "#009688",
      "#00bcd4",
      "#03a9f4",
      "#2196f3",
      "#3f51b5",
      "#673ab7",

      "#ff9800",
      "#ffc107",
      "#ffeb3b",
      "#cddc39",
      "#8bc34a",
      "#4caf50",

      "#ffb300",
      "#fdd835",
      "#43a047",
      "#00897b",
      "#00acc1",
      "#039be5",
    ];
    return colorArray;
  }



  // Award
  //
  //
  //
  //

  getAwardDropdown = () => {
	let awardDropdown = [
		{ label: "2022 MVPs", value: 0 },
		{ label: "2021 MVPs", value: 1 },
		{ label: "2020 MVPs", value: 2 },
		{ label: "2022 Employees of the Year", value: 3 },
		{ label: "2022 TSL Awards", value: 4 },
		{ label: "2021 TSL Awards", value: 5 },
		{ label: "2020 TSL Awards", value: 6 },
		{ label: "2019 FIFA League", value: 7 },
		{ label: "2018 FIFA League", value: 8 },
	];
	return awardDropdown
  }

  getAllAwards = () => {
	let allAwards = [
		{
			award: "2022 MVPs",
			department: [
				{
					name: "Eatbook",
					winners: [{ name: "Gabrielle Bong", type: "" }],
				},
				{
					name: "Human Resources",
					winners: [
						{ name: "Shu Hui Lee", type: "" },
						{ name: "Selina Lee", type: "" },
					],
				},
				{
					name: "MS News",
					winners: [{ name: "Jun An Lo", type: "" }],
				},
				{
					name: "TSL Creative",
					winners: [
						{
							name: "Doreen Fan",
							type: "",
						},
						{
							name: "Clement Sim",
							type: "",
						},
					],
				},
				{
					name: "TSL Tech",
					winners: [
						{ name: "Joshua Dominic", type: "" },
						{ name: "Allen Candelario", type: "" },
					],
				},
				{
					name: "TSL Editorial",
					winners: [
						{
							name: "Jessica Fang",
							type: "",
						},
						{ name: "Jessica Lai", type: "" },
						{ name: "Ra Krishnan", type: "" },
						{ name: "Kezia Tan", type: "" },
					],
				},
				{
					name: "TSL Regional Editorial",
					winners: [{ name: "Xin Tian Koh", type: "" }],
				},
			],
		},
		{
			award: "2021 MVPs",
			department: [
				{
					name: "TSL Editorial",
					winners: [{ name: "Kimberly Wong", type: "" }],
				},
				{
					name: "Admin",
					winners: [{ name: "Kaitlyn Leow", type: "" }],
				},
				{
					name: "TSL Regional",
					winners: [{ name: "Joelynn Wong", type: "" }],
				},
			],
		},
		{
			award: "2020 MVPs",
			department: [
				{
					name: "Admin",
					winners: [{ name: "Kaitlyn Leow", type: "" }],
				},
			],
		},
		{
			award: "2022 Employees of the Year",
			department: [
				{
					name: "Eatbook",
					winners: [{ name: "Beatrice Bowers", type: "" }],
				},
				{
					name: "TSL Editorial",
					winners: [
						{ name: "Ra", type: "" },
						{ name: "Kimberly Wong", type: "" },
					],
				},
				{
					name: "TSL PMO",
					winners: [{ name: "Steph Leong", type: "" }],
				},
				{
					name: "TSL Tech",
					winners: [{ name: "Mark Lee", type: "" }],
				},
				{
					name: "Youtube",
					winners: [{ name: "James Rainier", type: "" }],
				},
			],
		},
		{
			award: "2022 TSL Awards",
			department: [
				{
					name: "Business of The Year",
					winners: [{ name: "Eatbook", type: "" }],
				},
				{
					name: "Content Innovation of The Year",
					winners: [{ name: "The Smart Global", type: "" }],
				},
				{
					name: "Eatbook",
					winners: [
						{ name: "Michelle Put", type: "Most Viral News Article" },
						{ name: "Kay Enze", type: "Story of the Year" },
						{ name: "Gab Bong", type: "Most Creative Youtube Video" },
						{ name: "Casandra Nicholas", type: "Social Media Video of the Year" },
					],
				},
				{
					name: "Human Resources",
					winners: [
						{ name: "Shu Hui Lee", type: "Most Effective Employee Engagement" }
					],
				},
				{
					name: "MS News",
					winners: [
						{ name: "Iqmall Hayat", type: "Best Client Work" },
						{ name: "Jonathan Yee", type: "Story of the Year" },
						{ name: "Nur Sabrina & Joseph Eng", type: "Best Facebook Video" },
						{ name: "Sabrina Seng", type: "Best Infographic" },
					],
				},
				{
					name: "SMT",
					winners: [{ name: "Rafe Rojiken", type: "Social Media Post of the Year" }],
				},
				{
					name: "TSL Creative",
					winners: [
						{ name: "Brad Lee", type: "Content of the Year" },
						{ name: "John Lim", type: "Viral Senpai" },
						{
							name: "Julian Reyes, Jeslene Chia, Nicolai David, Dominic Shimizu, Eric Chow, Hafeez Harris, Sam Ngain, Jessalyn Chua",
							type: "Content of the Year",
						},
					],
				},
				{
					name: "TSL Editorial",
					winners: [
						{ name: "Josiah Neo", type: "Most Viral Article of the Year" },
						{ name: "Renae Cheng", type: "Story of the Year" },
					],
				},
				{
					name: "TSL Finance",
					winners: [{ name: "Rosemarie", type: "Biggest Gold Digger" }],
				},
				{
					name: "TSL PMO",
					winners: [
						{ name: "Darius Ng", type: "Most Outstanding Testimonial" },
						{ name: "Steph Leong", type: "Best Long Term Relationship" },
					],
				},
				{
					name: "TSL Regional",
					winners: [
						{ name: "Bank Fasaisirinan", type: "Best Performing Article of the Year" },
						{ name: "Pok", type: "Most Viral Video" },
					],
				},
				{
					name: "TSL Sales",
					winners: [
						{ name: "Lin Zhidan", type: "Close of the Year" },
						{ name: "Wilfred Ng", type: "Rookie of the Year" },
					],
				},
				{
					name: "TSL ThinkTank",
					winners: [
						{ name: "Mandon Lee", type: "Biggest Contract Assist" },
						{ name: "Jereld Ang", type: "Most Innovative Script" },
					],
				},
				{
					name: "Youtube",
					winners: [
						{ name: "Amanda Feng", type: "Most Viral Youtube Video of the Year" },
						{ name: "Fikah Zainal", type: "Most Creative Youtube Video of the Year" },
					],
				},
				{
					name: "Zula",
					winners: [
						{ name: "Nicole Yong", type: "Most Viral News Article" },
						{ name: "Jou Teng Tan", type: "Story of the Year" },
						{ name: "Shash Yoga", type: "Youtube Video of the Year" },
						{ name: "Cherrell Ng", type: "Social Media Post of the Year" },
					],
				},
			],
		},
		{
			award: "2021 TSL Awards",
			department: [
				{
					name: "TSL Editorial",
					winners: [
						{ name: "Raewyn Koh", type: "Best Written Article" },
						{ name: "Jessica Lai", type: "Best Viral Story" },
						{ name: "Renae Cheng", type: "Best Client Article" },
					],
				},
				{
					name: "TSL Regional",
					winners: [
						{ name: "Cha Yoo Kyung", type: "Most Viral Article" },
						{ name: "Asamaporn Anukularmphai", type: "Best Story" },
						{ name: "Danny Ko", type: "Most Viral Video" },
						{ name: "Prangphisut Daengdej", type: "Best Video" },
					],
				},
				{
					name: "MS News",
					winners: [
						{ name: "Jun An Lo", type: "Best Viral Story" },
						{ name: "Sabrina Seng", type: "Best Infographic" },
						{ name: "Iqmall Bin Hairy", type: "Best Meme" },
						{ name: "Cristian Lim", type: "Best Video" },
						{ name: "Amanda Yeap", type: "Best Client Work" },
					],
				},
				{
					name: "TSL Productions",
					winners: [
						{ name: "Seng Jueh Lim", type: "Best Directing" },
						{ name: "Eric Chow", type: "Best Editing" },
						{ name: "Jeslene Chia", type: "Best Editing" },
						{ name: "Julian Reyes", type: "Best Cinematography" },
					],
				},
				{
					name: "Eatbook",
					winners: [
						{ name: "Chiara Ang", type: "Most Creative Recipe" },
						{ name: "Yi En Chew", type: "Best Facebook Video" },
						{ name: "Gladys Lim", type: "Best Youtube Video" },
						{ name: "Jamie Tan", type: "Best Youtube Video" },
					],
				},
				{
					name: "Zula",
					winners: [
						{ name: "Nicole Yong", type: "Best Story" },
						{ name: "Jia Yi Yeow", type: "Best Youtube Video" },
					],
				},
				{
					name: "TSL Creative",
					winners: [
						{ name: "Huy Pham", type: "Most Viral Content" },
						{ name: "Clement Sim", type: "Fastest Producer" },
						{ name: "Pichan Dela Cruz", type: "Outstanding Facebook Video" },
						{ name: "Ian Sim", type: "Outstanding Graphic Content" },
					],
				},
				{
					name: "TSL Youtube",
					winners: [
						{ name: "Fikah", type: "Most Creative Video" },
						{ name: "Estee Woo", type: "Most Viral Video" },
					],
				},
				{
					name: "TSL Tech",
					winners: [
						{ name: "Dennis Wijaya", type: "Biggest Feature of the Year" },
						{ name: "Mark Lee", type: "Employee of the Year" },
					],
				},
				{
					name: "TSL ThinkTank",
					winners: [
						{ name: "Mandon Lee", type: "Biggest Contract Assist" },
						{ name: "Jereld Ang", type: "Smoothest Script" },
						{ name: "Hafeez Harris", type: "Brightest Idea" },
					],
				},
				{
					name: "TSL PMO",
					winners: [
						{ name: "Hafidzhin Sadali", type: "Outstanding Client Relationships" },
						{ name: "Isabelle Ong", type: "Most Outstanding Testimonial" },
						{ name: "Steph Leong", type: "Rising Star" },
					],
				},
				{
					name: "TSL Sales",
					winners: [
						{ name: "Sabriana Boon", type: "Rookie of the Year" },
						{ name: "Lynnette Lee", type: "Close of the Year" },
					],
				},
				{
					name: "TSL SMT",
					winners: [
						{ name: "Brenda Tan", type: "Best SMT" },
						{ name: "Clement Sim", type: "Best SMT" },
					],
				},
				{
					name: "TSL Tech",
					winners: [
						{ name: "Li Ying Chang", type: "Best Collab/Ad" },
						{ name: "Michelle Cheong", type: "Best Collab/Ad" },
					],
				},
				{
					name: "TSL Finance",
					winners: [{ name: "Jack Foo", type: "Biggest Cash Saved" }],
				},
			],
		},
		{
			award: "2020 TSL Awards",
			department: [
				{
					name: "TSL Editorial",
					winners: [
						{ name: "Bryan Yeong", type: "Best Perspective" },
						{ name: "Kezia Tan", type: "Best Viral Story" },
						{ name: "Jess Lai", type: "Best Article" },
					],
				},
				{
					name: "TSL Regional",
					winners: [
						{ name: "Jessica Fang", type: "Best Viral Story" },
						{ name: "Danny Ko", type: "Best Video" },
						{ name: "Josee Ng", type: "Best Individual Performer" },
					],
				},
				{
					name: "TSL Thailand",
					winners: [{ name: "Ra Krishnan", type: "Best Story" }],
				},
				{
					name: "MS News",
					winners: [
						{ name: "Amanda Yeap", type: "Best Viral Story" },
						{ name: "Fayyadhah Zainalabiden", type: "Best Investigative Piece" },
						{ name: "Joelynn Wong", type: "Best Infographic" },
						{ name: "Doreen Fan", type: "Best Meme" },
					],
				},
				{
					name: "TSL Productions",
					winners: [
						{ name: "Dominic Shimizu", type: "Best Cinematography" },
						{ name: "Nicolai David", type: "Best Editing" },
						{ name: "Seng Jueh Lim", type: "Best Directing" },
					],
				},
				{
					name: "Eatbook",
					winners: [
						{ name: "Chiara Ang", type: "Most Creative Recipe" },
						{ name: "Lery Villanueva", type: "Best Facebook Video" },
						{ name: "Hui Qing Koh", type: "Best Youtube Video" },
					],
				},
				{
					name: "Zula",
					winners: [
						{ name: "Annabelle Fernandez", type: "Best Story" },
						{ name: "Jielin Lu", type: "Best Youtube Video" },
					],
				},
				{
					name: "TSL Creative",
					winners: [
						{ name: "Wen Lin Tan", type: "Most Viral Content" },
						{ name: "Clement Sim", type: "Fastest Producer" },
						{ name: "Pichan Dela Cruz", type: "Outstanding Facebook Video" },
						{ name: "Clement Sim", type: "Outstanding Graphic Content" },
						{ name: "Huy Pham", type: "Outstanding Graphic Content" },
					],
				},
				{
					name: "TSL Youtube",
					winners: [
						{ name: "Bolin Chen", type: "Most Creative Video" },
						{ name: "Estee Woo", type: "Most Creative Video" },
						{ name: "Kimberly Wong", type: "Most Viral Video" },
					],
				},
				{
					name: "TSL ThinkTank",
					winners: [
						{ name: "Samantha Ngain", type: "Biggest Contract Assist" },
						{ name: "Hafeez Harris", type: "Most Innovative Idea" },
					],
				},
				{
					name: "TSL PMO",
					winners: [
						{ name: "Isabelle Ong", type: "Most Outstanding Testimonial" },
						{ name: "Celeste Loh", type: "Best Long-Term Relationship" },
					],
				},
				{
					name: "TSL Sales",
					winners: [
						{ name: "Ahshik Ansari", type: "Rookie of the Year" },
						{ name: "Lynnette Lee", type: "Close of the Year" },
					],
				},
				{
					name: "TSL SMT",
					winners: [{ name: "Fauzi Aziz", type: "Best SMT" }],
				},
				{
					name: "TSL Tech",
					winners: [{ name: "Pearly Choong", type: "Best Feature" }],
				},
				{
					name: "TSL Finance",
					winners: [{ name: "Jack Foo", type: "Biggest Cash Saved" }],
				},
			],
		},
		{
			award: "2019 FIFA League",
			department: [
				{
					name: "Champion",
					winners: [{ name: "Hafeez Harris", type: "" }],
				},
			],
		},
		{
			award: "2018 FIFA League",
			department: [
				{
					name: "Champion",
					winners: [{ name: "Bryan Choo", type: "" }],
				},
			],
		},
	];
	return allAwards;
  }

  //    _____                    ___  _              _
  //   |_   _|_  _  _ __  ___   / __|| |_   ___  __ | |__
  //     | | | || || '_ \/ -_) | (__ | ' \ / -_)/ _|| / /
  //     |_|  \_, || .__/\___|  \___||_||_|\___|\__||_\_\
  //          |__/ |_|

  /**
   * Checks for {} | null | undefined
   * Counters react-router-dom update for PoorMansUnknown / unknown types
   * @param  {Readonly<any>} props
   * @return boolean value
   */
  checkLocState(props: Readonly<any>) {
    // (Readonly<RouteComponentProps<any>> | Readonly<{ children?: ReactNode; }>)

    // Check props location for errors
    return (
      props.location.state !== null &&
      props.location.state !== undefined &&
      props.location.state !== {}
    );
  }

  //    ___ ___ _____ _____ ___ _  _  ___ ___
  //   / __| __|_   _|_   _|_ _| \| |/ __/ __|
  //   \__ \ _|  | |   | |  | || .` | (_ \__ \
  //   |___/___| |_|   |_| |___|_|\_|\___|___/
  //
  async getUserSettings(settingsType: string) {
    const res = await this.httpClient.get(`/api/setting/${settingsType}`);
    return res;
  }
  async saveUserSettings(settingsType: string, settingsObject: object) {
    const res = await this.httpClient.post(
      `/api/setting/${settingsType}`,
      settingsObject
    );
    return res;
  }

  getQueryFromURL(location: any) {
    return new URLSearchParams(location.search);
  }
}
